<template>
  <v-card outlined>
    <v-card-title>{{ header }}</v-card-title>
    <v-list two-line>
      <template v-for="(item, index) in items">
        <v-list-item :key="index+item.title" class="text-start">
          <v-list-item-avatar class="ma-auto">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-6" v-if="index !== items.length - 1" :key="index"/>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'KurccSiteBranchDialogContentSection',
  props: {
    header: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
